import React, { useCallback, useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import {
	getAttendanceQRData,
	getUserAttendanceList,
} from '../../services/event'
import './style.scss'
import {
	Button,
	Card,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	debounce,
} from '@material-ui/core'
import { exportFromJSON, getAreas, isMobileScreen } from '../../utils'
import { ACCOMODATION_CATEGORY, EVENT_BOOKING_STATUS } from '../../constants'
import { CloudDownload } from '@material-ui/icons'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../constants/actions'
import { Autocomplete } from '@material-ui/lab'
import { searchUser } from '../../services/user'
import { getFullFormattedDateTimeString } from '../../components/Calender/helpers'

const getKeysInSameOrder = (obj) => {
	let out = []
	let keys = Object.keys(obj)
	let localKeys = [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 7, 11, 12, 13]
	for (let key of localKeys) {
		if (keys.includes(String(key))) {
			out.push(key)
		}
	}
	return out
}

const AttendanceScreen = () => {
	const [filters, setFilters] = useState({
		connectedAreaId: '',
		facilitator: null,
		eventId: '',
		per_page: 10,
		page: 1,
	})
	const [filterOptions, setFilterOptions] = useState({
		facilitatorOptions: [],
		sessionOptions: [],
	})
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [rowCount, setRowCount] = useState(0)
	const dispatch = useDispatch()

	const updateBookingData = useCallback(
		debounce((q) => {
			const connectedAreaId = filters.connectedAreaId
			const facilitator = filters.facilitator?._id
			setLoading(true)
			getUserAttendanceList({
				...filters,
				connectedAreaId,
				facilitator,
			})
				.then((res) => {
					setData(res.data.data.filter((el) => el.status !== 1))
					setRowCount(res.data.total_results)
					setLoading(false)
				})
				.catch((e) => {
					setLoading(false)
				})
		}, 1000),
		[filters]
	)

	useEffect(() => {
		updateBookingData()
	}, [filters, updateBookingData])

	useEffect(() => {
		searchUser({
			q: '',
			userRole: 3,
			areaId: filters.connectedAreaId ?? '',
			per_page: 100,
		}).then((res) => {
			setFilterOptions((filter) => ({
				...filter,
				facilitatorOptions: res.data.data,
			}))
		})
	}, [filters])

	useEffect(() => {
		getAttendanceQRData().then((data) => {
			setFilterOptions((filter) => ({
				sessionOptions: data?.data?.data,
			}))
		})
	}, [])

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page: page,
			per_page: pageSize,
		}))
	}
	const onPageSizeChange = ({ pageSize }) => {
		setFilters((f) => ({
			...f,
			page: 1,
			per_page: pageSize,
		}))
	}

	const rowWidthConfig = {
		name: { default: { flex: 0.15 }, mobile: { width: 150 } },
		phone: { default: { flex: 0.1 }, mobile: { width: 150 } },
		email: { default: { flex: 0.2 }, mobile: { width: 200 } },
		facilitator: { default: { flex: 0.15 }, mobile: { width: 150 } },
		area: { default: { flex: 0.2 }, mobile: { width: 150 } },
		status: { default: { flex: 0.2 } },
		accomodation: { default: { flex: 0.15 }, mobile: { width: 150 } },
		transportation: { default: { flex: 0.15 }, mobile: { width: 100 } },
		attendedAt: { default: { flex: 0.15 }, mobile: { width: 100 } },
	}

	const headCells = [
		{
			field: 'name',
			numeric: false,
			headerName: 'Name',
			sortable: false,
		},
		{
			field: 'phone',
			numeric: false,
			headerName: 'Phone',
			sortable: false,
		},
		{
			field: 'email',
			numeric: false,
			headerName: 'Email',
			sortable: false,
		},
		{
			field: 'area',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'facilitator',
			numeric: false,
			headerName: 'Facilitator',
			sortable: false,
		},
		{
			field: 'attendedAt',
			numeric: false,
			headerName: 'Attended At',
			sortable: false,
		},
	]

	const onFilterChange = (name, value) => {
		setFilters((f) => ({
			...f,
			[name]: value,
			page: 1,
		}))
	}

	const download = () => {
		const todayDate = format(new Date(), 'dd-MM-yyyy')
		const eventName = filterOptions.sessionOptions?.find(
			(el) => el._id === filters.eventId
		)?.title
		const fileName =
			`${eventName || 'All Events'} ` + 'Attendance ' + todayDate

		dispatch({ type: SHOW_MAIN_LOADER })
		getUserAttendanceList({
			...filters,
			page: 1,
			per_page: 10000,
		})
			.then((res) => {
				exportFromJSON({
					data: res.data?.data?.map((s) => ({
						name: s.userId?.initiatedName || s.userId?.name,
						email: s.userId?.email,
						phone: s.userId?.phone,
						area: s.connectedAreaId?.name,
						facilitator:
							s.facilitator?.initiatedName || s.facilitator?.name,
						attendedDate: getFullFormattedDateTimeString(
							s?.createdAt
						)?.split(' at ')[0],
						attendedTime: getFullFormattedDateTimeString(
							s?.createdAt
						).split(' at ')[1],
					})),
					fileName,
				})
				dispatch({ type: HIDE_MAIN_LOADER })
			})
			.catch((e) => {
				dispatch({ type: HIDE_MAIN_LOADER })
			})
	}

	return (
		<Card className="p-5">
			<Typography variant="h4">Attendance List</Typography>
			<div className="screen report-screen mt-3">
				<div className="filter-header">
					<div className="filters-group">
						<FormControl
							variant="outlined"
							id="fixed-width-dropdown"
							style={{ maxWidth: 300, marginRight: 10 }}
						>
							<InputLabel id="demo-simple-select-outlined-label">
								Session
							</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								value={filters.eventId}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Session"
								name="eventId"
							>
								<MenuItem value={''}>All Session</MenuItem>
								{filterOptions.sessionOptions.map((el) => (
									<MenuItem value={el._id}>
										{el.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl
							variant="outlined"
							id="fixed-width-dropdown"
							style={{ maxWidth: 300 }}
						>
							<InputLabel id="demo-simple-select-outlined-label">
								Area
							</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								value={filters.connectedAreaId}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Area"
								name="connectedAreaId"
							>
								<MenuItem value={''}>All Areas</MenuItem>
								{getAreas().map((area) => (
									<MenuItem value={area._id}>
										{area.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Autocomplete
							options={filterOptions.facilitatorOptions || []}
							getOptionLabel={(option) =>
								option.initiatedName || option.name
									? `${option.initiatedName || option.name}` +
									  (option.phone ? ` (${option.phone})` : '')
									: option.initiatedName || option.name || ''
							}
							defaultValue={filters.facilitator}
							filterSelectedOptions
							value={filters.facilitator}
							getOptionSelected={(option, value) =>
								option._id === value._id
							}
							onChange={(e, newValue) => {
								onFilterChange('facilitator', newValue)
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Facilitator"
									placeholder="Start typing Facilitator Name"
								/>
							)}
							className="facilitator-dropdown"
						/>
					</div>

					<Button
						startIcon={<CloudDownload />}
						color="primary"
						variant="contained"
						onClick={download}
						style={{ marginBottom: '20px' }}
					>
						Download
					</Button>
				</div>
				<DataGrid
					rows={
						data.map((s) => ({
							...s,
							id: s._id,
							name: s.userId.initiatedName || s.userId.name,
							email: s.userId.email,
							phone: s.userId.phone,
							area: s.connectedAreaId?.name,
							status: EVENT_BOOKING_STATUS[s.status],
							facilitator:
								s.facilitator?.initiatedName ||
								s.facilitator?.name,
							accomodation:
								ACCOMODATION_CATEGORY[s.accomodationCategory]
									?.name,
							transportation: s.transportationAmount
								? 'Yes'
								: 'No',
							attendedAt: getFullFormattedDateTimeString(
								s.attendTime
							),
						})) || []
					}
					autoHeight
					columns={headCells.map((col) => ({
						...col,
						sortable: true,
						disableClickEventBubbling: true,
						// resizable: true,
						...(isMobileScreen()
							? rowWidthConfig[col.field].mobile ||
							  rowWidthConfig[col.field].default
							: rowWidthConfig[col.field].default),
					}))}
					pageSize={filters.per_page}
					rowCount={rowCount}
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					pagination
					paginationMode="server"
					onPageChange={handleUserData}
					disableColumnFilter
					loading={loading}
					onPageSizeChange={onPageSizeChange}
				/>
			</div>
		</Card>
	)
}

export default AttendanceScreen
